<template>
  <div class="ma-10">
    <div class="container-box mt-10" style="padding: 35px 25px">
      <div class="list-career">
        <div
          v-for="(item, index) in careers"
          :key="index"
          class="box-category"
          @click="goToPage(item.id)"
        >
          <h1 class="box-category-title" >
            {{ item.name }}
          </h1>
          <div style="display: flex; width: 250px">
            <div style="margin-right: auto">
              <p style="margin-right: auto" class="box-category-text">
                {{ item.events_name.join(", ") }}
              </p>
              <div class="box-category-count">
                <div>
                  <h3 class="box-category-count-title">
                    {{ item.total_events }}
                  </h3>
                  <h5 class="box-category-count-text">
                    Total <br />
                    Program
                  </h5>
                </div>
                <div>
                  <h3 class="box-category-count-title">
                    {{ item.total_registered }}
                  </h3>
                  <h5 class="box-category-count-text">
                    Peserta <br />
                    Terdaftar
                  </h5>
                </div>
              </div>
            </div>
            <div @click="goToPage(item.id)"><Arrow :direct="true" /></div>
          </div>
        </div>
      </div>
      <div class="pagination-container">
        <div class="pagination-container-btn">
          <Button
            v-if="
              Math.floor(
                this.careers.length / 10 +
                  (this.careers.length % 10 === 0 ? 0 : 1)
              ) > 1
            "
            class="m-auto"
            name="SELANJUTNYA"
            height="54px"
          />
        </div>
        <div class="pagination-page-container" style="width: 20%">
          <h5>Halaman</h5>
          <h5 class="pagination-page">{{ currentPage }}</h5>
          <h5>
            dari
              {{
                Math.floor(
                  this.careers.length / 10 +
                    (this.careers.length % 10 === 0 ? 0 : 1)
                )
              }}
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";
import "vue2-datepicker/index.css";

export default {
  name: "CareerAppList",
  components: {
    Button,
  },
  data() {
    return {
      careers: [],
      currentPage: 1,
      total: 0,
    };
  },
  methods: {
    async getCareers() {
      try {
   
        const response = await this.$crm_http.get(`/v1/transaction/event/category`)
        this.careers = response.data.data.event_categories;
      } catch (error) {
        console.error("Error fetching transaction events:", error);
      }
    },
    goToPage(id) {
      this.$router.push(`/crm-event/${id}`);
    },
  },
  mounted() {
    this.getCareers();
  },
};
</script>

<style scoped>
.pagination-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination-page {
  border: 1px solid #d3d6e1;
  border-radius: 8px;
  padding: 10px;
  width: 50px;
  display: flex;
  justify-content: center;
  margin: 0px 10px;
}
.box-category2 {
  border: 1px solid #2d46cf;
  width: 300px;
  padding: 15px 25px;
  border-radius: 15px;
}
.box-category {
  border: 1px solid #d3d6e1;
  width: 300px;
  padding: 15px 25px;
  cursor: pointer;
  border-radius: 15px;
}
.box-category-count {
  display: flex;
  padding: 15px 25px 15px 25px;
  column-gap: 38px;
  background-color: #f1f6ff;
  border-radius: 5px;
}
.text-title-chart {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
}
.box-category-text {
  color: #7b7e8c;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  width: 215px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.box-category-title {
  color: #36373f;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
}
.box-category-count-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #2d46cf;
}
.box-category-count-text {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
}
.pagination-container {
  margin-top: 35px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination-container-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}
.ctn-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action-head {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.my-datepicker {
  width: 100%;
}

.my-datepicker ::v-deep .mx-input {
  height: 54px !important;
  border-radius: 12px !important;
}

.f-search {
  border: 1px solid #d3d6e1;
  width: 100%;
  height: 55px;
  gap: 10px;
  display: flex;
  border-radius: 10px;
  background-color: white;
  padding-left: 20px;
  margin-top: -7px;
}

.f-search-ic {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 25px;
}

.f-input {
  width: 100%;
  background: transparent;
  border: none;
  padding-top: 5px;
}

.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}

.list-career {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  gap: 10px 20px;
}

.list-career-ctn {
  padding: 15px 20px;
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid #d3d6e1;
}

.list-career-ctn img {
  width: 130px;
}

.list-career-ctn h2 {
  margin: 0;
}

.list-career-ctn p {
  margin: 0;
  color: #36373f;
  font-size: 14px;
}

.career-data .label {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 15px;
}

.career-data .label div {
  padding: 5px 10px;
  border-radius: 30px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.career-data .label div p {
  font-size: 11px !important;
  font-weight: 400;
  padding: 0;
  margin: 0;
  margin-top: 2px;
  color: #36373f;
}

.box-total {
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin: 10px 0;
  width: min-content;
  background-color: #f1f6ff;
}

.btn-stoke-blue {
  border: 1px solid #acafbf;
  border-radius: 12px;
  color: #acafbf;
  font-weight: 600;
  cursor: pointer;
  gap: 10px;
  margin-top: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  padding: 11px 0;
  background-color: white;
  margin: 20px 0 0 20px !important;
}

.text-limit {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  visibility: visible;
  font-weight: 500;
}

.btn-stoke-blue span {
  font-family: "Poppins", sans-serif;
  font-weight: normal !important;
  font-size: 16px;
  min-width: max-content;
}
</style>
