var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ma-10"},[_c('div',{staticClass:"container-box mt-10",staticStyle:{"padding":"35px 25px"}},[_c('div',{staticClass:"list-career"},_vm._l((_vm.careers),function(item,index){return _c('div',{key:index,staticClass:"box-category",on:{"click":function($event){return _vm.goToPage(item.id)}}},[_c('h1',{staticClass:"box-category-title"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticStyle:{"display":"flex","width":"250px"}},[_c('div',{staticStyle:{"margin-right":"auto"}},[_c('p',{staticClass:"box-category-text",staticStyle:{"margin-right":"auto"}},[_vm._v(" "+_vm._s(item.events_name.join(", "))+" ")]),_c('div',{staticClass:"box-category-count"},[_c('div',[_c('h3',{staticClass:"box-category-count-title"},[_vm._v(" "+_vm._s(item.total_events)+" ")]),_vm._m(0,true)]),_c('div',[_c('h3',{staticClass:"box-category-count-title"},[_vm._v(" "+_vm._s(item.total_registered)+" ")]),_vm._m(1,true)])])]),_c('div',{on:{"click":function($event){return _vm.goToPage(item.id)}}},[_c('Arrow',{attrs:{"direct":true}})],1)])])}),0),_c('div',{staticClass:"pagination-container"},[_c('div',{staticClass:"pagination-container-btn"},[(
            Math.floor(
              this.careers.length / 10 +
                (this.careers.length % 10 === 0 ? 0 : 1)
            ) > 1
          )?_c('Button',{staticClass:"m-auto",attrs:{"name":"SELANJUTNYA","height":"54px"}}):_vm._e()],1),_c('div',{staticClass:"pagination-page-container",staticStyle:{"width":"20%"}},[_c('h5',[_vm._v("Halaman")]),_c('h5',{staticClass:"pagination-page"},[_vm._v(_vm._s(_vm.currentPage))]),_c('h5',[_vm._v(" dari "+_vm._s(Math.floor( this.careers.length / 10 + (this.careers.length % 10 === 0 ? 0 : 1) ))+" ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"box-category-count-text"},[_vm._v(" Total "),_c('br'),_vm._v(" Program ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"box-category-count-text"},[_vm._v(" Peserta "),_c('br'),_vm._v(" Terdaftar ")])
}]

export { render, staticRenderFns }