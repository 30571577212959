<template>
  <div class="forms">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <div class="body-form">
      <!-- breadcrumbs -->
      <v-breadcrumbs :items="breadcrumbs" divider=">" class="breadcrumbs">
      </v-breadcrumbs>
      <div class="cart-form">
        <div class="form-ctn">
          <br /><br />
          <div class="client">
            <div class="content-form">
              <p>Title</p>
              <v-radio-group v-model="form.title" row>
                <v-radio
                  v-for="title in titles"
                  :key="title.value"
                  :label="title.label"
                  :value="title.value"
                ></v-radio>
              </v-radio-group>
            </div>

            <div class="content-form">
              <p>Nama Lengkap</p>
              <v-text-field
                :disabled="isWatch"
                outlined
                class="form-input mt-2"
                placeholder="Masukkan Nama Lengkap"
                v-model="form.full_name"
                dense
                clearable
              ></v-text-field>
            </div>

            <div class="content-form">
              <p>No. Handphone</p>
              <v-text-field
                :disabled="isWatch"
                outlined
                class="form-input mt-2"
                placeholder="Masukkan No. Handphone"
                v-model="form.phone_number"
                dense
                clearable
              ></v-text-field>
            </div>

            <div class="content-form">
              <p>Email</p>
              <v-text-field
                :disabled="isWatch"
                outlined
                class="form-input mt-2"
                placeholder="Masukkan Email"
                v-model="form.email"
                dense
                clearable
              ></v-text-field>
            </div>

            <div class="content-form">
              <p>Tanggal Lahir</p>
              <v-text-field
                outlined
                class="form-input mt-2"
                v-model="form.birthdate"
                type="date"
                dense
                clearable
              ></v-text-field>
            </div>
          </div>

          <div class="content-form">
            <p>Roles</p>
            <v-select
              v-model="form.role"
              :items="roles"
              :item-text="'label'"
              :item-value="'value'"
              outlined
              dense
              clearable
              placeholder="Pilih Role"
            ></v-select>
          </div>

          <div class="content-form">
            <p>Kata Sandi</p>
            <v-text-field
              :type="showPassword ? 'text' : 'password'"
              v-model="form.password"
              append-icon="mdi-eye"
              @click:append="togglePasswordVisibility"
              outlined
              class="form-input mt-2"
              placeholder="Masukkan Kata Sandi"
              dense
              clearable
            ></v-text-field>
          </div>

          <div class="content-form">
            <p>Konfirmasi Kata Sandi</p>
            <v-text-field
              :type="showConfirmPassword ? 'text' : 'password'"
              v-model="form.confirmPassword"
              append-icon="mdi-eye"
              @click:append="toggleConfirmPasswordVisibility"
              outlined
              class="form-input mt-2"
              placeholder="Konfirmasi Kata Sandi"
              dense
              clearable
            ></v-text-field>
          </div>
          <div class="submit-box" v-if="!isWatch">
            <Button name="Cancel" outline class="me-3" @click="onCancel" />
            <Button
              name="Save"
              @click="onSubmit"
              :disabled="!validData"
              style="background-color: #3b54d9; color: #ffffff"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "@/components/ilustration/Loading.vue";
import Button from "@/components/Button.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  name: "UserActiveForm",
  data() {
    return {
      id: null,
      name: "",
      isLoading: false,
      isWatch: false,
      message: "",
      isPopup: false,
      selectedTitle: null, // Untuk menyimpan pilihan yang dipilih
      titles: [
        {
          label: "Tuan",
          value: "Tuan",
        },
        {
          label: "Nyonya",
          value: "Nyonya",
        },
        { label: "Nona", value: "Nona" },
      ],
      roles: [
        { label: "Admin", value: 1 },
        { label: "Member", value: 2 },
        { label: "Pengunjung", value: 3 },
        { label: "Keuangan", value: 4 },
        { label: "Manajemen", value: 5 },
        { label: "Menejemen Konten", value: 6 },
      ],
      breadcrumbs: [
        { text: "User Management", disabled: false, href: "/user" },
        { text: "User Aktif", disabled: false, href: "/user" },
        { text: "Buat User Baru", disabled: true, href: "/user/new" },
      ],
      showPassword: false,
      showConfirmPassword: false,
      form: {
        title: "",
        full_name: "",
        phone_number: "",
        email: "",
        birthdate: "",
        role: "",
        password: "",
        confirmPassword: "",
      },
    };
  },
  components: {
    Loading,
    Button,
    Popup
  },
  computed: {
    validData() {
      return (
        this.form.full_name &&
        this.form.phone_number &&
        this.form.email &&
        this.form.birthdate
      );
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        const birthdateWithTime = this.form.birthdate
          ? `${this.form.birthdate}T00:00:00Z`
          : null;
        await this.$crm_http.post("/v1/user", {
          ...this.form,
          full_name: this.form.full_name,
          phone_number: this.form.phone_number,
          email: this.form.email,
          birthdate: birthdateWithTime,
          gender: this.form.title,
          role: this.form.role,
          password: this.form.password,
        });
        setTimeout(() => {
          this.$router.push("/user");
        }, 2000);
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    onCancel() {
      this.$router.push("/user");
    },
  },
};
</script>
<style scoped>
.forms {
  overflow-x: hidden;
}
.body-form {
  justify-content: center;
  padding: 30px 80px;
  min-height: 100vh;
  width: 100vw;
}

.breadcrumbs {
  text-align: center;
}
.form-ctn {
  padding: 0 40px;
}
.cart-form {
  background-color: white;
  position: relative;
  width: 70%;
}

.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}

.content-form {
  margin-top: -5px;
}
.content-form p {
  font-size: 16px;
  margin: 3px 0;
  font-weight: bold;
}

.submit-box {
  display: flex;
  justify-content: right;
}

button {
  margin-bottom: 30px;
}

@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
</style>
