<template>
    <div style="margin: 41px 40px">
      <Popup
        :message="message"
        v-if="isPopup"
        @close="() => (isPopup = false)"
      />
      <Loading v-if="isLoading" />
      <Modal
        width="40%"
        v-if="isModalDetail"
        @close="() => (isModalDetail = false)">
        <div class="inner-container-modal-detail">
          <div class="container-title-modal-detail">
            <DocBlue width="24" height="24" />
            <h1>Detail Data Leads</h1>
          </div>
          <div class="container-detail-modal-detail">
            <div class="container-detail-top-modal-detail">
              <div class="ctn-detail">
                <p class="detail-label">ID Code</p>
                <p class="detail-value">{{ form.id || '-' }}</p>
              </div>
              <div class="ctn-detail">
                <p class="detail-label">Waktu Data Masuk</p>
                <p class="detail-value">{{ form.created_at || '-' }}</p>
              </div>
            </div>
            <div class="container-detail-middle-modal-detail">
              <div class="container-detail-middle-left-modal-detail">
                <div class="ctn-detail">
                  <p class="detail-label">Nama Perusahaan</p>
                  <p class="detail-value">{{ form.corporate_name || '-' }}</p>
                </div>
                <div class="ctn-detail">
                  <p class="detail-label">Website Perusahaan</p>
                  <p class="detail-value">{{ form.web_url || '-' }}</p>
                </div>
              </div>
              <div class="container-detail-middle-right-modal-detail">
                <div class="ctn-detail">
                  <p class="detail-label">Nama Lengkap PIC</p>
                  <p class="detail-value">{{ form.full_name || '-' }}</p>
                </div>
                <div class="ctn-detail">
                  <p class="detail-label">Okupasi PIC</p>
                  <p class="detail-value">{{ form.job_pic || '-' }}</p>
                </div>
                <div class="ctn-detail">
                  <p class="detail-label">Email PIC</p>
                  <p class="detail-value">{{ form.email || '-' }}</p>
                </div>
                <div class="ctn-detail">
                  <p class="detail-label">No. Whatsapp PIC</p>
                  <p class="detail-value">{{ form.phone_number || '-' }}</p>
                </div>
              </div>
            </div>
            <div class="container-detail-bottom-modal-detail">
              <div class="ctn-detail">
                <p class="detail-label">Pelatihan</p>
                <p class="detail-value">{{ form.program || '-' }}</p>
              </div>
              <div class="ctn-detail status">
                <p class="detail-label">Status</p>
                <p class="detail-value">{{ $helpers.nameCRMb2bList(form.status) || '-' }}</p>
              </div>
              <div class="ctn-detail">
                <p class="detail-label">Note</p>
                <p class="detail-value">{{ form.notes || '-' }}</p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <SideModal v-if="isModalSide" width="576px">
        <div class="container-side-modal">
          <div class="title-outer-container">
            <div class="title-container">
              <DocPencil v-if="form.id" width="24" height="24" />
              <DocBlue v-else width="24" height="24" />
              <h2>
                {{ form.id ? 'Ubah Data Leads' : 'Buat Data Leads Baru' }}
              </h2>
            </div>
            <div @click.prevent="onCloseModal" style="cursor: pointer">
              <CloseIc :width="20" :height="20" />
            </div>
          </div>
          <div class="container-form-side-modal">
            <div class="form-ctn">
              <div class="form-ctn-left">
                <label class="form-label"
                  >Nama Perusahaan <span style="color: #c63c38">*</span></label
                >
              </div>
              <div class="form-ctn-right">
                <v-text-field
                  class="form-input text-form"
                  v-model="form.corporate_name"
                  outlined
                  placeholder="Masukkan Nama Perusahaan"
                  hide-details
                />
              </div>
            </div>
            <div class="form-ctn">
              <div class="form-ctn-left">
                <label class="form-label"
                  >Website Perusahaan</label
                >
              </div>
              <div class="form-ctn-right">
                <v-text-field
                  class="form-input text-form"
                  v-model="form.web_url"
                  outlined
                  placeholder="Masukkan Website Perusahaan"
                  hide-details
                />
              </div>
            </div>
            <div class="form-ctn">
              <div class="form-ctn-left">
                <label class="form-label"
                  >Nama Lengkap PIC <span style="color: #c63c38">*</span></label
                >
              </div>
              <div class="form-ctn-right">
                <v-text-field
                  class="form-input text-form"
                  v-model="form.full_name"
                  outlined
                  placeholder="Masukkan Nama Lengkap PIC"
                  hide-details
                />
              </div>
            </div>
            <div class="form-ctn">
              <div class="form-ctn-left">
                <label class="form-label"
                  >Okupasi PIC <span style="color: #c63c38">*</span></label
                >
              </div>
              <div class="form-ctn-right">
                <v-text-field
                  class="form-input text-form"
                  v-model="form.job_pic"
                  outlined
                  placeholder="Masukkan Okupasi PIC"
                  hide-details
                />
              </div>
            </div>
            <div class="form-ctn">
              <div class="form-ctn-left">
                <label class="form-label"
                  >Email PIC <span style="color: #c63c38">*</span></label
                >
              </div>
              <div class="form-ctn-right">
                <v-text-field
                  class="form-input text-form"
                  v-model="form.email"
                  outlined
                  placeholder="Masukkan Email PIC"
                  hide-details
                />
              </div>
            </div>
             <div class="form-ctn">
              <div class="form-ctn-left">
                <label class="form-label"
                  >No. Whatsapp PIC <span style="color: #c63c38">*</span></label
                >
              </div>
              <div class="form-ctn-right">
                <v-text-field
                  class="form-input text-form"
                  type="text"
                  v-model="form.phone_number"
                  outlined
                  placeholder="Masukkan No. Whatsapp PIC"
                  hide-details
                />
              </div>
            </div>
            <div class="form-ctn">
              <div class="form-ctn-left">
                <label class="form-label"
                  >Kategori Pelatihan <span style="color: #c63c38">*</span></label
                >
              </div>
              <div class="form-ctn-right">
                <v-select flat outlined color="#ACAFBF" persistent-hint placeholder="Pilih Kategori Pelatihan"
                  v-model="program" :items="courseOffer" :item-text="'title'" :item-value="'value'"
                  @change="getCourseMaterial"  />
              </div>
            </div>
            <div class="form-ctn">
              <div class="form-ctn-left">
                <label class="form-label"
                  >Program Pelatihan <span style="color: #c63c38">*</span></label
                >
              </div>
              <div class="form-ctn-right">
                <v-select
                outlined
                color="#ACAFBF"
                placeholder="Pilih Program Pelatihan"
                v-model="form.program"
                :items="courseMaterial"
                :item-text="'title'" :item-value="'title'"
                style="border-radius: 10px !important; background-color: white;"
                hide-details
              />
              </div>
            </div>
            <div class="form-ctn">
              <div class="form-ctn-left">
                <label class="form-label"
                  >Status <span style="color: #c63c38">*</span></label
                >
              </div>
              <div class="form-ctn-right">
                 <v-select
                  outlined
                  color="#ACAFBF"
                  placeholder="Pilih Status"
                  v-model="form.status"
                  :items="$helpers.statusCRMb2bList()"
                  :item-text="'name'"
                  :item-value="'value'"
                  style="border-radius: 10px !important; background-color: white;"
                  hide-details
                />
              </div>
            </div>
            <div class="form-ctn">
              <div class="form-ctn-left">
                <label class="form-label">Catatan</label>
              </div>
              <div class="form-ctn-right">
                <v-textarea
                  outlined
                  v-model="form.notes"
                  class="mt-2 rounded-sm"
                  hide-details=""
                  placeholder="Tulis di sini..."
                  rows="4"
                ></v-textarea>
              </div>
            </div>
            <div class="line-under mt-10"></div> 
            <div
              style="
                display: flex;
                justify-content: flex-end;
                gap: 10px;
                margin-top: 100px;
              "
            >
              <Button
                name="BATALKAN"
                outline
                width="150px"
                height="45px"
                @click="() => onCloseModal()"
              />
              <Button
                :name="form.id ? 'SIMPAN' : 'BUAT DATA'"
                width="150px"
                height="45px"
                @click="onSubmit"
              />
            </div>
          </div>
        </div>
      </SideModal>
      <Modal
        width="40%"
        v-if="isModalDownload"
        @close="() => (isModalDownload = false)">
        <h1 class="modal-title">Konfirmasi</h1>
  
        <p style="margin: 20px 0px" class="detail-value">
          Apakah Anda yakin akan mengunduh data transaksi?
        </p>
        <div class="btn-container-2">
          <export-excel
            style="cursor: pointer"
            class="btn-simpan-2"
            :data="corporate"
            worksheet="sheet 1"
            name="crm-TRAINING.xls"
          >
            YA, UNDUH
          </export-excel>
  
          <button
            style="margin-left: 10px"
            @click.prevent="isModalDownload = false"
            class="color-text-gray btn-batalkan"
          >
            BATALKAN
          </button>
        </div>
      </Modal>
      <Modal
        width="40%"
        v-if="isModalDelete"
        @close="() => (isModalDelete = false)">
        <div class="inner-container-modal-delete">
          <h1 class="modal-title">Konfirmasi Hapus Data</h1>
          <p style="margin-top: 10px; font-weight: 400; font-size: 14px; line-height: 24px; color: #575966;">
            Apakah kamu yakin ingin menghapus data Leads ini?
          </p>
          <div class="btn-container-2">
            <button
            @click.prevent="() => (isModalDelete = false)"
            class="btn-batalkan"
            >
            BATALKAN
          </button>
          <button
          style="margin-left: 16px;"
            @click.prevent="() => onDeleteLead(form.id)"
            class="btn-hapus"
          >
            YA, HAPUS
          </button>
          </div>
      </div>
      </Modal>
  
      <div class="page">
        <h1
          class="text-page"
          style="
            margin-right: 10px;
            font-size: 28px;
            color: #36373f;
            font-weight: #36373f;
          "
        >
          CORP TRAINING
        </h1>
        <button @click.prevent="() => openAddLeadModal()" class="ml-auto btn-create">
          <Add  :width="25" :height="25" />
          Buat Leads Baru
        </button>
        <button @click.prevent="getDownload(true)" class="ml-3 btn-download">
          <DocumentDownload :fill="'#162ca2'" :width="20" :height="20" />
          Unduh Data
        </button>
      </div>
  
      <div class="body">
        <div class="container-search">
          <input type="text" placeholder="Nama Perusahaan" v-model="filter.company_name" class="input-search" />
          <date-picker
            class="my-datepicker"
            placeholder="Date time"
            valueType="format"
            v-model="filter.date"
            range
          ></date-picker>
          <v-select
            outlined
            placeholder="Status"
            v-model="filter.status"
            :items="$helpers.statusCRMb2bList()"
            :item-text="'name'"
            :item-value="'value'"
            style="border-radius: 10px !important; background-color: white"
            hide-details
          />
          <button class="btn-serach" @click.prevent="getData">TERAPKAN</button>
        </div>
        <div class="container-tabel">
          <table class="tabel borderless-table">
            <thead style="border: 2px solid black">
              <tr>
                <th class="table-head border-tabel">ID Code</th>
                <th class="table-head border-tabel">Waktu Data Masuk</th>
                <th class="table-head border-tabel">Nama Perusahaan</th>
                <th class="table-head border-tabel">Nama PIC</th>
                <th class="table-head border-tabel">Okupasi PIC</th>
                <th class="table-head border-tabel">Email PIC</th>
                <th class="table-head border-tabel">No. Whatsapp PIC</th>
                <th class="table-head border-tabel">Kategori Kolaborasi</th>
                <th class="table-head border-tabel">Status</th>
                <th class="table-floting border-tabel">Action</th>
              </tr>
            </thead>
            <tbody v-if="corporate.length">
              <tr
                v-for="(data, key) in corporate"
                :key="key"
                :style="{
                  backgroundColor: key % 2 === 1 ? '#f5f6f9' : 'transparent',
                }"
              >
                <td class="table-body tbody">{{ data.id }}</td>
                <td class="table-body tbody">{{ $helpers.getDateFull(data.created_at)}}</td>
                <td class="table-body tbody">{{ data.corporate_name }}</td>
                <td class="table-body tbody">{{ data.full_name }}</td>
                <td class="table-body tbody">{{ data.job_pic }}</td>
                <td class="table-body tbody">{{ data.email }}</td>
                <td class="table-body tbody">{{ data.phone_number }}</td>
                <td class="table-body tbody">{{ data.program }}</td>
                <td class="table-body tbody">{{ $helpers.nameCRMb2bList(data.status) }}</td>
                <td
                  :style="{
                    backgroundColor: key % 2 === 1 ? '#f5f6f9' : '',
                  }"
                  class="table-floting"
                >
                  <div class="warp-btn-action">
                    <button class="btn-action" @click.prevent="openEditLeadModal(data.id)">
                      UBAH
                    </button>
                    <button
                      @click.prevent="openDetailLeadModal(data.id)"
                      class="btn-action"
                    >
                      LIHAT
                    </button>
                    <button
                      @click.prevent="openDeleteLeadModal(data.id)"
                      class="btn-action"
                    >
                      HAPUS
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style="align-items: center; margin: 20px 20px 0px 20px"
          class="d-flex"
        >
          <div>
            <v-row justify="center" v-if="total >= 1">
        <v-col cols="4">
          <v-container class="max-width">
            <v-pagination
              v-model="currentPage"
              class="my-4"
              :length="total"
              @input="getData"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
          </div>
          <h5 style="font-weight: 400" class="text-jumlah">
            Menampilkan <span style="font-weight: 700">{{ Math.floor((currentPage - 1) * perPage + corporate.length) }}</span> dari
            <span style="font-weight: 700">{{ totalData }}</span>
          </h5>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Button from "@/components/Button.vue";
  import Modal from "@/components/Modal.vue";
  import SideModal from "@/components/SideModal.vue";
  import DatePicker from "vue2-datepicker";
  import DocBlue from "@/components/icons/DocBlue.vue";
  import DocPencil from "@/components/icons/DocPencil.vue";
  import CloseIc from "@/components/icons/Close.vue";
  import Add from "@/components/icons/Add.vue";
  import DocumentDownload from "@/components/icons/DocumentDownload.vue";
  import Popup from "@/components/others/Popup.vue";
  import Loading from "@/components/ilustration/Loading.vue";

  export default {
    props: ["id", "categorys", "riwayat"],
    components: { Loading, Popup, Modal, DocumentDownload, DatePicker, Add, SideModal, DocBlue, DocPencil, CloseIc, Button },
    data() {
      return {
        dateRange: {
          startDate: new Date(),
          endDate: new Date(),
        },
        courseOffer: [
        {
          img: "/img/corporate/tech-4.png",
          title: "General Corporate Training",
          desc: "Berbasis pada pelatihan dan pengembangan skill talenta untuk meningkatkan performa perusahaan. Pelatihan mencakup pengetahuan, keterampilan, dan sikap, sementara pengembangan berfokus pada karir jangka panjang. Training Needs Analysis diupayakan dalam mewujudkan program berjalan secara maksimal",
          value: 0,
        },
        {
          img: "/img/corporate/tech-3.png",
          title: "Certified Corporate Training",
          desc: "Berbasis pada pelatihan talenta melalui Upskilling dan New Skilling untuk meningkatkan performa perusahaan yang mencakup pengetahuan, keterampilan, dan sikap. Menerapkan Training Needs Analysis dan sertifikasi dari lisensi global sebagai bukti tercapainya program dan validasi peningkatan performa talenta.",
          value: 1,
        },
      ],
        currentPage: 1,
        total: 0,
        totalData:0,
        perPage: 10,
        courseMaterial: [],
        corporate: [],
        program: null,
        corporateDetail: null,
        pagination: null,
        isModalDetail: false,
        dowload: [],
        isModalDownload: false,
        isModalSide: false,
        isModalDelete: false,
        isPopup: false,
        message: "",
        isSearch: "",
        isLoading: false,
        isStatus: "PAID",
        form: {
          id: '',
          corporate_name: '',
          gender: '',
          web_url: '',
          full_name: '',
          job_pic: '',
          email: '',
          phone_number: '',
          program: '',
          status: '',
          notes: '',
          category: "TRAINING"
        },
        tipe_kolaborasi: [],
        filter: {
          company_name: "",
          start_date: "",
          end_date: "",
          date: '',
          status: ""
        },
      };
    },
    methods: {
      async getData() {
        this.isLoading = true;
        this.filter.start_date = this.filter.date[0] || "";
      this.filter.end_date = this.filter.date[1] || "";
        try {
          const response = await this.$crm_http.get(
            `/v1/corporate-registration?category=TRAINING&company_name=${this.filter.company_name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&status=${this.filter.status}&page=${this.currentPage}&per_page=${this.perPage}&sort_by=DESC`
          )
          this.corporate = response.data.data.corporate_registration
          if (!this.corporate) this.corporate = []
            const t = parseInt(response.data.data.total);
            this.total = Math.ceil(t / this.perPage);
            this.totalData = response.data.data.total;
            this.isLoading = false;
        } catch (err) {
          this.message = err.response.data.data
          this.isPopup = true
          this.isLoading = false;
        }
      },
      async onSubmit() {
        this.isLoading = true;
        try {
          if (this.form.id) {
        await this.$crm_http.put(
          `/v1/corporate-registration/${this.form.id}`,
          this.form
        );
      } else {
        await this.$crm_http.post(`/v1/corporate-registration`, this.form);
      }
      this.isLoading = false;
      this.onCloseModal();
      this.getData();    
        } catch (err) {
          this.isLoading = false;
          this.message = err.response.data.data
          this.isPopup = true
        }
      },
      async getCourseMaterial() {
      try {
        const resp = await this.$web_http.get(
          `/v1/corporate-training?category=${this.program ? "CERTIFIED" : "GENERAL"
          }`
        );
        this.courseMaterial = resp.data.data.corporate_trainings;
      } catch (error) {
        console.log(error);
      }
    },
      async onDeleteLead() {
        this.isLoading = true;
        try {
          await this.$crm_http.delete("/v1/corporate-registration/" + this.form.id);
          this.isLoading = false;
          this.isModalDelete = false
          this.onCloseModal();
          this.getData();
        } catch (err) {
          this.isLoading = false;
          this.message = err.response.data.data
          this.isPopup = true
        }
      },
      async getDownload(e) {
        this.isModalDownload = e;
      },
      openAddLeadModal() {
        this.form = {
          id: '',
          corporate_name: '',
          gender: '',
          web_url: '',
          full_name: '',
          job_pic: '',
          email: '',
          phone_number: '',
          program: '',
          status: '',
          notes: '',
          category: "TRAINING"
        }
        this.isModalSide = true
      },
      openEditLeadModal(id) {
        this.form = this.corporate.find(item => item.id === id)
        this.isModalSide = true
      },
      openDetailLeadModal(id) {
        this.form = this.corporate.find(item => item.id === id)
        this.isModalDetail = true
      },
      openDeleteLeadModal(id) {
        this.form = this.corporate.find(item => item.id === id)
        this.isModalDelete = true
      },
      onCloseModal() {
        this.isModalSide = false;
        this.form = {
          id: '',
          corporate_name: '',
          gender: '',
          web_url: '',
          full_name: '',
          job_pic: '',
          email: '',
          phone_number: '',
          program: '',
          status: '',
          notes: '',
          category: "TRAINING"
        }
      }
    },
    mounted() {
      this.getData();
    },
  };
  </script>
  
  <style scoped>
  .my-datepicker {
    width: auto;
  }
  .my-datepicker ::v-deep .mx-input {
    height: 48px !important;
    border-radius: 12px !important;
  }
  
  .btn-container-2 {
    margin-top: 32px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .btn-batalkan {
    background-color: #ffffff;
    color: #7b7e8c;
    width: 200px;
    height: 48px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 1px solid #d3d6e1;
  }
  .btn-hapus {
    background-color: #162CA2;
    color: #FFFFFF;
    width: 200px;
    height: 48px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .btn-simpan {
    background-color: #acafbf;
    color: #ffffff;
    width: 156px;
    height: 44px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 1px solid #acafbf;
  }
  .btn-simpan-2 {
    background-color: #2d46cf;
    color: #ffffff;
    width: 156px;
    height: 44px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 1px solid #acafbf;
  }
  .td-transaksi {
    justify-content: space-between;
    margin-top: 5px;
    width: 300px;
  }
  .td-transaksi-2 {
    width: 100px;
  }
  .container-search {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  .custom-control-container {
    font-weight: 400;
    font-size: 14px;
    color: #575966;
    border: 1px solid #d3d6e1;
    width: 233px;
    height: 48px;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .long-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 160px;
  }
  .tbody {
    margin: 0;
    font-weight: 400;
    border-collapse: collapse;
    font-size: 14px;
  }
  .tbody-color-red {
    color: #b80f0a;
  }
  .tbody-color-gray {
    color: #7b7e8c;
  }
  .container-tabel {
    background-color: white;
    margin: 20px;
    overflow-x: auto;
  }
  .tabel {
    position: relative;
    border: none;
    width: 100%;
    border-collapse: collapse;
  }
  .table-head {
    min-width: 200px;
    background-color: white;
    white-space: nowrap;
    width: auto;
    padding: 10px;
  }
  .border-tabel {
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    border-bottom: 2px solid #e5e7e9;
  }
  .table-body {
    min-width: 200px;
    height: 141px;
    white-space: nowrap;
    width: auto;
    padding: 10px;
    text-align: center;
  }
  .table-floting {
    white-space: nowrap;
    width: auto;
    min-width: 200px;
    text-align: center;
    position: sticky;
    right: 0;
    background-color: white;
  }
  .warp-btn-action {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
  .btn-action {
    background-color: white;
    width: 79px;
    height: 38px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #575966;
    font-size: 14px;
    font-weight: 500;
    color: #2d46cf;
    border: 1px solid #acafbf;
  }
  
  .input-search {
    width: 233px;
    border: 1px solid #d3d6e1;
    height: 48px;
    border-radius: 10px;
    padding: 0px 20px;
    font-weight: 400;
    font-size: 14px;
    color: #575966;
  }
  
  .btn-serach {
    background-color: #162ca2;
    color: white;
    width: 156px;
    height: 44px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-prev {
    background-color: #ffffff;
    color: #162ca2 !important;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 1px solid #162ca2;
  }
  .btn-download {
    background-color: #ffffff;
    color: #162ca2 !important;
    width: 156px;
    height: 44px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 1px solid #162ca2;
  }
  .btn-create {
    background-color: #162ca2;
    color: #ffffff !important;
    width: 200px;
    height: 44px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 1px solid #162ca2;
  }
  .text-jumlah {
    font-size: 12px;
    font-weight: 400px;
  }
  .pegenation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
  }
  .pegenation-text {
    font-size: 14px;
    font-weight: 700;
    margin-left: 15px;
    margin-right: 15px;
  }
  .text-page {
    font-weight: bold;
    font-size: 16px;
    color: #162ca2;
  }
  .color-text-blue {
    color: #4456b4;
  }
  .color-text-gray {
    color: #acafbf;
  }
  .page {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }
  .body {
    background: #ffffff;
    padding: 42px 22px;
    border-radius: 10px;
    height: fit-content;
  }
  
  .container-side-modal {
    padding: 24px;
  }
  
  .title-outer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ACAFBF;
    padding-bottom: 16px;
  }
  
  .title-container {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .title-container h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #36373F;
  }
  
  .container-form-side-modal {
    margin-top: 32px;
  }
  
  .form-ctn {
    margin-top: 30px;
  }
  
  .rounded-sm {
    border-radius: 11px !important;
    background-color: white;
  }
  
  .form-label {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }
  
  ::v-deep .v-input input {
    padding: 12px 30px;
    font-family: "Poppins", sans-serif;
  }
  
  .form-label {
    border-radius: 10px;
  }
  
  ::v-deep v-text-field {
    border-radius: 10px;
  }
  
  ::v-deep .row {
    margin: -12px;
  }
  
  ::v-deep .col {
    padding: 5px 20px !important;
  }
  
  ::v-deep .text-form .v-input__slot {
    border-radius: 10px;
    border: 1px solid rgba(211, 214, 225, 1);
    background-color: white;
  }
  
  ::v-deep .form-upload {
    height: 62px !important;
    padding-left: 10px;
    border: 1px solid rgba(211, 214, 225, 1);
    border-radius: 10px;
  }
  
  ::v-deep .form-upload .v-input__slot {
    height: 62px !important;
    background-color: transparent;
    border: none !important;
  }
  
  ::v-deep .form-upload fieldset {
    display: none !important;
    /* Menyembunyikan fieldset */
  }
  
  ::v-deep .form-upload .v-text-field__details {
    display: none;
    border: none !important;
  }
  
  .note-file {
    font-weight: 400;
    font-size: 12px;
    color: #b80f0a;
    margin-bottom: 0px !important;
  }
  
  .inner-container-modal-detail {
    padding: 16px;
  }
  
  .inner-container-modal-detail p {
    margin: 0;
    text-align: left;
  }
  
  .container-title-modal-detail {
    display: flex;
    gap: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #ACAFBF;
  }
  
  .container-title-modal-detail h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #36373F;
  }
  
  .container-detail-modal-detail {
    margin-top: 24px;
  }
  
  .container-detail-top-modal-detail, .container-detail-middle-modal-detail {
    padding-bottom: 16px;
    border-bottom: 1px solid #D3D6E1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: start;
  }
  
  .container-detail-middle-modal-detail, .container-detail-bottom-modal-detail{
    margin-top: 16px;
  }
  
  .ctn-detail {
    margin: 8px 0;
  }
  
  .detail-label {
    font-weight: 400;
    color: #7B7E8C;
    font-size: 13px;
    line-height: 18px;
  }
  
  .detail-value {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #36373F;
    margin-top: 4px;
  }
  
  .status > .detail-value {
    color: #162CA2;
  }
  
  .inner-container-modal-delete {
    padding: 24px 16px;
  }
  
  .modal-title {
    color: #36373F;
    font-weight: 00;
    font-size: 20px;
    line-height: 30px;
  }
  </style>
  