<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Modal v-if="isModal" @close="() => (isModal = false)" width="480px">
      <h3>Konfirmasi Hapus Data</h3>
      <p>Apakah kamu yakin ingin menghapus data Leads ini?</p>
      <div style="display: flex; gap: 20px; margin-top: 30px">
        <Button
          name="BATALKAN"
          width="100%"
          outline
          @click="() => (isModal = false)"
        />
        <Button name="YA, HAPUS" width="100%" @click="onDelete" />
      </div>
    </Modal>
    <Modal
      v-if="isModalDetail"
      @close="() => (isModalDetail = false)"
      width="40%"
    >
      <div class="title-container line-under">
        <DocBlue />
        <h2 class="modal-title me-3" style="font-weight: bolder">
          Detail Data Pelamar
        </h2>
      </div>
      <div class="modal-body">
        <div class="data-list line-under">
          <div class="detail-data">
            <p class="detail-label">Applicant ID</p>
            <p class="detail-value">{{ applicantDetail.no_invoice }}</p>
          </div>
          <div class="detail-data">
            <p class="detail-label">Waktu Data Masuk</p>
            <p class="detail-value">
              {{ applicantDetail.datetime_purchased }}
              WIB
            </p>
          </div>
        </div>
        <h4 class="mt-5">Personal Data</h4>
        <div class="data-list">
          <div class="detail-data">
            <p class="detail-label">Titel</p>
            <p class="detail-value">{{ applicantDetail.gender }}</p>
          </div>
          <div class="detail-data">
            <p class="detail-label">Tanggal Lahir</p>
            <p class="detail-value">{{ applicantDetail.birthday }}</p>
          </div>
        </div>
        <div class="data-list">
          <div class="detail-data">
            <p class="detail-label">Nama Lengkap</p>
            <p class="detail-value">{{ applicantDetail.user_name }}</p>
          </div>
          <div class="detail-data">
            <p class="detail-label">Motivasi Mengikuti Pelatihan</p>
            <p class="detail-value">{{ applicantDetail.motivation_note }}</p>
          </div>
        </div>
        <div class="data-list">
          <div class="detail-data">
            <p class="detail-label">Email</p>
            <p class="detail-value">{{ applicantDetail.email }}</p>
          </div>
        </div>
        <div class="data-list">
          <div class="detail-data">
            <p class="detail-label">No. Whatsapp</p>
            <p class="detail-value">
              {{ $helpers.phoneFormat(applicantDetail.phone) }}
            </p>
          </div>
        </div>

        <h4 class="mt-5">Voucher</h4>
        <div style="padding: 20px 0px" class="line-under">
          <div class="detail-data d-flex">
            <p class="detail-label" style="text-align: justify; width: 100%">
              Kode Voucher
              <span style="float: right; color: #36373f">{{
                applicantDetail.voucher
              }}</span>
            </p>
          </div>
        </div>

        <div v-if="applicantDetail.status === 'CANCEL'">
          <h4 class="mt-5">Catatan</h4>
          <div class="data-list line-under">
            <p class="detail-value">{{ applicantDetail.notes }}</p>
          </div>
        </div>
        <h4 class="mt-5">Status</h4>
        <div class="data-list">
          <p class="detail-value status-text">
            {{ applicantDetail.status.replace(/_/g, " ") }}
          </p>
        </div>
      </div>
    </Modal>
    <SideModal v-if="isModalSide">
      <div
        class="title-container line-under"
        style="justify-content: space-between"
      >
        <div class="title-container" style="width: 90%; padding: 0">
          <DocPencil />
          <h2 class="modal-title me-3" style="font-weight: bolder">
            Tamabah Data Leads
          </h2>
        </div>
        <div @click="onCloseModal" class="cursor-pointer">
          <CloseIc />
        </div>
      </div>
      <div class="career-detail-modal">
        <div class="career-detail-item-modal">
          <div class="form-ctn">
            <div class="form-ctn-left">
              <label class="form-label"
                >Titel <span style="color: #c63c38">*</span></label
              >
            </div>
            <div class="form-ctn-right">
              <v-select
                outlined
                color="#ACAFBF"
                placeholder="Tipe Pekerjaan"
                v-model="applicantDetail.gender"
                :items="gender"
                :item-text="'name'"
                :item-value="'value'"
                style="border-radius: 10px !important; background-color: white"
                hide-details
              />
            </div>
          </div>
          <div class="form-ctn">
            <div class="form-ctn-left">
              <label class="form-label"
                >Nama Lengkap <span style="color: #c63c38">*</span></label
              >
            </div>
            <div class="form-ctn-right">
              <v-text-field
                class="form-input text-form"
                v-model="applicantDetail.user_name"
                outlined
                placeholder="Masukkan Nama Lengkap Anda"
                hide-details
              />
            </div>
          </div>
          <div class="form-ctn">
            <div class="form-ctn-left">
              <label class="form-label"
                >Email <span style="color: #c63c38">*</span></label
              >
            </div>
            <div class="form-ctn-right">
              <v-text-field
                class="form-input text-form"
                v-model="applicantDetail.email"
                outlined
                placeholder="Masukkan Emai Anda"
                hide-details
              />
            </div>
          </div>
          <div class="form-ctn">
            <div class="form-ctn-left">
              <label class="form-label"
                >No. Whatsapp <span style="color: #c63c38">*</span></label
              >
            </div>
            <div class="form-ctn-right">
              <v-text-field
                class="form-input text-form"
                type="text"
                v-model="applicantDetail.phone"
                outlined
                placeholder="Misal: 0813-xxxxx-xxxx"
                hide-details
              />
            </div>
          </div>
          <div class="form-ctn">
            <div class="form-ctn-left">
              <label class="form-label">Tanggal Lahir</label>
            </div>
            <div class="form-ctn-right">
              <date-picker
                class="my-datepicker"
                v-model="dates"
                placeholder="Date time"
                valueType="format"
              ></date-picker>
            </div>
          </div>
          <div class="form-ctn">
            <div class="form-ctn-left">
              <label class="form-label">Motivasi Mengikuti Pelatihan Ini</label>
            </div>
            <div class="form-ctn-right">
              <v-textarea
                outlined
                v-model="applicantDetail.motivation_note"
                class="mt-2 rounded-sm"
                hide-details=""
                placeholder="Tulis catatan tambahan di sini..."
                rows="4"
              ></v-textarea>
            </div>
          </div>

          <div class="line-under mt-10"></div>
          <div v-if="applicantDetail.status === 'DRAFT'" class="form-ctn">
            <div class="form-ctn-left">
              <label class="form-label">Kode Voucher</label>
            </div>
            <div class="form-ctn-right">
              <v-text-field
                class="form-input text-form"
                v-model="applicantDetail.voucher"
                outlined
                placeholder="Masukkan Kode Voucher Anda"
                hide-details
              />
            </div>
          </div>
          <div v-if="applicantDetail.status === 'CANCEL'" class="form-ctn">
            <div class="form-ctn-left">
              <label class="form-label">Notes</label>
            </div>
            <div class="form-ctn-right">
              <v-textarea
                outlined
                v-model="applicantDetail.notes"
                class="mt-2 rounded-sm"
                hide-details=""
                placeholder="Tulis catatan tambahan di sini..."
                rows="4"
              ></v-textarea>
            </div>
          </div>
        </div>

        <div
          style="
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            margin-top: 100px;
          "
        >
          <Button
            name="BATALKAN"
            outline
            width="150px"
            height="45px"
            @click="() => onCloseModal()"
          />
          <Button
            name="SIMPAN"
            width="150px"
            height="45px"
            @click="() => onSubmitApp()"
          />
        </div>
      </div>
    </SideModal>
    <div class="header-info">
      <div class="career-top">
        <span
          class="yellow-text font-poppins"
          @click="$router.push('/crm-event')"
          >{{
            this.category
              .split("-")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")
          }}</span
        >
        <div>
          <Arrow
            :width="20"
            :height="20"
            direct="left"
            strokeColor="white"
            class="icon"
          />
        </div>
        <span class="font-poppins">Data Leads</span>
      </div>
    </div>
    <div
      class="header-info table"
      style="margin: 40px 0px; background-color: white; padding: 24px"
    >
      <div class="career">
        <div class="career-info">
          <div class="career-title">
            <h1>{{ details.name }}</h1>
          </div>
          <div class="career-info-ctn">
            <span class="font-poppins">{{ details.schedule }}</span>
          </div>
        </div>
        <div class="career-data">
          <div class="label">
            <div style="background-color: #f1f6ff">
              <clock-ic />
              <p>
                {{
                  details.learning_method === "Online" ? "Via Zoom" : "On Site"
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="career-info" style="margin-top: 10px">
          <div class="career-info-ctn">
            <span class="font-poppins"
              >Ditutup pada
              <b class="font-poppins">{{
                details.registration_close_date
              }}</b></span
            >
          </div>
        </div>
      </div>
    </div>

    <div v-if="!history" class="header-info table headdetail">
      <h3>Ringkasan Laporan</h3>
      <div style="padding: 20px 0px" class="d-flex">
        <div class="border-headdetail" style="margin-right: 10px">
          <h5>Total Leads</h5>
          <h1>{{ chart.total_transaction }}</h1>
          <h5>Per 24 November 2024</h5>
        </div>
        <div class="border-headdetail">
          <h5>Avg. Incoming Leads</h5>
          <h1>{{ chart.average_transaction_per_day.toFixed(1) }}</h1>
          <h5>Per Hari</h5>
        </div>
      </div>
      <div class="d-flex" style="width: 100%">
        <div class="pie-chart">
          <apexchart type="pie" :options="chartOptions" :series="series" />
          <div
            class="d-flex"
            style="
              flex-direction: column;
              justify-content: center;
              margin: 20px 0px 0px 20px;
            "
          >
            <div class="d-flex" style="align-items: center">
              <div class="list-date" style="background-color: #37a1eb"></div>
              <h5>Menunggu Pembayaran</h5>
            </div>
            <div class="d-flex" style="align-items: center; margin-top: 10px">
              <div class="list-date" style="background-color: #4bc0c0"></div>
              <h5>Pembayaran Lunas</h5>
            </div>
            <div class="d-flex" style="align-items: center; margin-top: 10px">
              <div class="list-date" style="background-color: #ff9f40"></div>
              <h5>Batal (Kadaluarsa)</h5>
            </div>
            <div class="d-flex" style="align-items: center; margin-top: 10px">
              <div class="list-date" style="background-color: #ff6485"></div>
              <h5>Batal</h5>
            </div>
          </div>
        </div>
        <div class="line-chart">
          <div class="d-flex" style="align-items: center; margin-bottom: 30px">
            <div class="d-flex" style="align-items: center">
              <div class="list-date" style="background-color: #37a1eb"></div>
              <h5>Leads Masuk</h5>
            </div>
            <div style="margin-left: auto">
              <v-select
                outlined
                color="#ACAFBF"
                placeholder="Waktu"
                v-model="filter.work_type"
                :items="workTypeList"
                :item-text="'name'"
                :item-value="'value'"
                style="border-radius: 10px !important; background-color: white"
                hide-details
              />
            </div>
          </div>
          <apexchart type="area" :options="chartOptions2" :series="series2" />
        </div>
      </div>
    </div>
    <div v-if="history" class="header-info table headdetail">
      <h3>Ringkasan Laporan</h3>
      <div class="header-info overflow-auto">
        <div style="padding: 20px 0px; width: auto" class="d-flex">
          <div class="border-headdetail mx-2">
            <h5>Total Leads</h5>
            <h1>{{ chart.total_transaction }}</h1>
            <h5>Per 24 November 2024</h5>
          </div>
          <div
            v-for="(items, index) in chartOptions.labels"
            :key="index"
            class="border-headdetail mx-2"
          >
            <h5>{{ items.replace(/_/g, " ") }}</h5>
            <h1>{{ series[index] }}</h1>
            <h5>Per 24 November 2024</h5>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-10 table" style="background-color: white">
      <div>
        <div class="d-flex" style="height: 100%">
          <div class="d-flex" style="height: fit-content; margin: auto 0px">
            <h3 style="height: fit-content">Data Leads</h3>
          </div>
          <export-excel
            class="ml-auto d-flex download-excel"
            :data="careerApplicationExcel"
            worksheet="sheet 1"
            :name="`${this.category}-events ${new Date().getDate()}-${
              new Date().getMonth() + 1
            }-${new Date().getFullYear()}.xls`"
          >
            <Download-inbox></Download-inbox>
          </export-excel>
        </div>

        <div class="line-under mt-5 mb-5"></div>
      </div>
      <v-toolbar flat>
        <v-row gap="4">
          <v-col cols="3">
            <div class="action-head">
              <div class="f-search">
                <div class="f-search-ic">
                  <img src="/img/icons/search.png" alt="Search Ic" />
                </div>
                <div style="width: 90%">
                  <v-text-field
                    v-model="filter.position"
                    type="text"
                    class="f-input"
                    placeholder="Cari Pekerjaan"
                    dense
                    flat
                    solo
                  ></v-text-field>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="2">
            <date-picker
              class="my-datepicker"
              v-model="filter.date"
              placeholder="Date time"
              valueType="format"
              range
            ></date-picker>
          </v-col>
          <v-col cols="3">
            <v-select
              outlined
              color="#ACAFBF"
              placeholder=""
              v-model="filter.status"
              :items="status"
              :item-text="'name'"
              :item-value="'value'"
              style="border-radius: 10px !important; background-color: white"
              hide-details
            />
          </v-col>
          <v-col cols="2">
            <Button
              name="TERAPKAN"
              width="100%"
              height="54px"
              @click="() => getCareerApplication()"
            />
          </v-col>
        </v-row>
      </v-toolbar>
      <div class="container-tabel">
        <table class="tabel borderless-table">
          <thead style="border: 2px solid black">
            <tr v-if="headers.length">
              <th
                v-for="(result, key) in headers"
                :key="key"
                class="table-head border-tabel"
              >
                {{ result.text }}
              </th>
            </tr>
          </thead>
          <tbody v-if="careerApplication.length">
            <tr
              v-for="(result, key) in careerApplication"
              :key="key"
              :style="{
                backgroundColor: key % 2 === 1 ? '#f5f6f9' : 'transparent',
              }"
            >
              <td class="table-body tbody">{{ result.no_invoice }}</td>
              <td class="table-body tbody">{{ result.datetime_purchased }}</td>
              <td class="table-body tbody">{{ result.user_name }}</td>
              <td class="table-body tbody">{{ result.email }}</td>
              <td class="table-body tbody">{{ result.phone }}</td>
              <td class="table-body tbody">{{ result.birthday }}</td>
              <td class="table-body tbody">{{ result.voucher }}</td>
              <td class="table-body">
                <div
                  class="d-flex"
                  style="flex-direction: column; text-align: left"
                >
                  <div class="d-flex td-transaksi">
                    <p class="tbody tbody-color-gray">Normal Price</p>
                    <p class="tbody ml-auto td-transaksi-2">
                      IDR {{ result.normal_price }}
                    </p>
                  </div>
                  <div class="d-flex td-transaksi">
                    <p class="tbody tbody-color-gray">Discount</p>
                    <p class="tbody ml-auto td-transaksi-2">
                      IDR {{ result.discount }}
                    </p>
                  </div>
                  <div class="d-flex td-transaksi">
                    <p class="tbody tbody-color-gray">Current Price</p>
                    <p class="tbody tbody-color-red ml-auto td-transaksi-2">
                      IDR {{ result.current_price }}
                    </p>
                  </div>
                </div>
              </td>
              <td class="table-body tbody">{{ result.status }}</td>

              <td
                :style="{
                  backgroundColor: key % 2 === 1 ? '#f5f6f9' : '',
                }"
                class="table-floting"
              >
                <div class="warp-btn-action">
                  <button
                    @click.prevent="watchItem(result)"
                    class="btn-stoke-blue"
                  >
                    <span>LIHAT</span>
                  </button>
                  <button class="btn-stoke-blue" @click="updateItem(result)">
                    <span>UBAH</span>
                  </button>
                  <button
                    @click.prevent="deleteItem(result)"
                    class="btn-stoke-blue"
                  >
                    <span>Hapus</span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="pagination-container">
        <div class="pagination-container-btn">
          <Button
            v-if="
              Math.floor(
                this.careerApplication.length / 10 +
                  (this.careerApplication.length % 10 === 0 ? 0 : 1)
              ) > 1
            "
            class="m-auto"
            name="SELANJUTNYA"
            height="54px"
          />
        </div>
        <div class="pagination-page-container" style="width: 20%">
          <h5>Halaman</h5>
          <h5 class="pagination-page">1</h5>
          <h5>
            dari
              {{
                Math.floor(
                  this.careerApplication.length / 10 +
                    (this.careerApplication.length % 10 === 0 ? 0 : 1)
                )
              }}
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import SideModal from "@/components/SideModal.vue";
import Button from "@/components/Button.vue";
import ClockIc from "@/components/icons/ClockLv.vue";

import DocBlue from "@/components/icons/DocBlue.vue";
import DatePicker from "vue2-datepicker";

import Loading from "@/components/ilustration/Loading.vue";
import getAuthentification from "@/utils/badrequest.js";
import Arrow from "@/components/icons/Arrow.vue";
import DocPencil from "@/components/icons/DocPencil.vue";
import CloseIc from "@/components/icons/Close.vue";
import VueApexCharts from "vue-apexcharts";
import DownloadInbox from "@/components/icons/Download-inbox.vue";
export default {
  props: ["ids", "category", "history"],
  components: {
    DocBlue,
    DocPencil,
    CloseIc,
    SideModal,
    DatePicker,
    apexchart: VueApexCharts,
    Loading,
    Arrow,
    Modal,
    Button,
    ClockIc,
    DownloadInbox,
  },
  data() {
    return {
      series: [44, 55, 13, 43, 22],
      series2: [
        {
          name: "Data B",
          data: [11, 32, 45, 32, 34, 52, 41],
        },
      ],
      chartOptions: {
        dataLabels: {
          enabled: false,
        },
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        labels: ["Label A", "Label B", "Label C", "Label D", "Label E"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        legend: {
          show: false,
          position: "bottom",
        },
      },
      chartOptions2: {
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        grid: {
          show: true,
          strokeDashArray: 5,
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        labels: [
          "Label A",
          "Label B",
          "Label C",
          "Label D",
          "Label E",
          "Label D",
          "Label E",
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      gender: [
        {
          name: "Nona",
          value: "Nona",
        },
        {
          name: "Tuan",
          value: "Tuan",
        },
        {
          name: "Nyonya",
          value: "Nyonya",
        },
      ],
      status: [
        {
          name: "Draft",
          value: "DRAFT",
        },
        {
          name: "WAIT",
          value: "WAITING_FOR_PAYMENT",
        },
        {
          name: "PAID",
          value: "PAID",
        },
        {
          name: "Cancel",
          value: "CANCEL",
        },
        {
          name: "Expired",
          value: "EXPIRED",
        },
      ],
      isModal: false,
      currentPage: 1,
      isModalDetail: false,
      isHistory: false,
      isLoading: false,
      total: 0,
      search: "",
      id: 0,
      careerDetail: null,
      isModalSide: false,
      resume_cv: null,
      portfolio_cv: null,
      applicantDetail: {
        career_id: "",
        full_name: "",
        email: "",
        phone_number: "",
        linkedin_url: "",
        resume_url: "",
        portfolio_url: "",
        location: "",
        status: "",
        current_company: "",
        current_level: "",
        website: "",
        notes: "",
      },
      form: {
        career_id: "",
        full_name: "",
        email: "",
        phone_number: "",
        linkedin_url: "",
        resume_url: "",
        portfolio_url: "",
        location: "",
        current_company: "",
        current_level: "",
        website: "",
        notes: "",
      },
      statuses: [
        {
          text: "Terkirim",
          value: "SUBMITTED",
        },
        {
          text: "Di Proses",
          value: "PROCESS",
        },
        {
          text: "Di Terima",
          value: "APPROVE",
        },
        {
          text: "Di Tolak",
          value: "REJECT",
        },
      ],
      headers: [
        {
          text: "No. Invoice",
          value: "no_invoice",
          width: 270,
          sortable: false,
        },
        {
          text: "Waktu Pendaftaran",
          value: "datetime_purchased",
          width: 190,
          sortable: false,
        },
        {
          text: "Nama Pelanggan",
          value: "user_name",
          width: 270,
          sortable: false,
        },
        { text: "Email", value: "email", width: 180, sortable: false },
        {
          text: "No. Whatsapp",
          value: "phone",
          width: 180,
          sortable: false,
        },
        {
          text: "Tanggal Lahir",
          value: "birthday",
          width: 300,
          sortable: false,
        },
        {
          text: "Kode Voucher",
          value: "voucher",
          width: 300,
          sortable: false,
        },
        { text: "Harga", value: "price", width: 250, sortable: false },
        { text: "Status", value: "status", width: 150, sortable: false },
        { text: "ACTIONS", value: "actions", width: 250, sortable: false },
      ],
      headerExcelData: [
        {
          label: "Nama Lengkap",
          field: "full_name",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "No.Telp",
          field: "phone_number",
        },
        {
          label: "Linkedin URL",
          field: "linkedin_url",
        },
        {
          label: "Resume URL",
          field: "resume_url",
        },
        {
          label: "Portfolio URL",
          field: "portfolio_url",
        },
        {
          label: "Date applied",
          field: "created_at",
        },
        {
          label: "Alamat",
          field: "location",
        },
        {
          label: "Perusahan Saatini",
          field: "current_company",
        },
        {
          label: "Posisi sebelumnya",
          field: "current_level",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Catatan",
          field: "notes",
        },
      ],
      filter: {
        position: "",
        status: "",
        start_date: "",
        end_date: "",
        date: [],
      },
      workTypeList: [
        {
          name: "Week",
          value: "Week",
        },
        {
          name: "Month",
          value: "Month",
        },
        {
          name: "Year",
          value: "Year",
        },
      ],
      isFormEdit: false,
      careerApplicationExcel: [],
      careerApplication: [],
      dates: null,
      chart: null,
      details: null,
    };
  },
  methods: {
    async getCareerApplication() {
      this.filter.start_date = this.filter.date[0] || "";
      this.filter.end_date = this.filter.date[1] || "";
      try {
        const response = await this.$crm_http.get(`/v1/transaction/event/${
            this.ids
          }?page=1&per_page=10&keyword=${this.filter.position}&status=${
            this.filter.status
          }&date_period=${
            this.filter.start_date === "" && this.filter.end_date === ""
              ? ""
              : this.filter.start_date + ":" + this.filter.end_date
          }`)
        this.careerApplication = response.data.data.transaction_event;
      } catch (error) {
        console.error("Error fetching transaction events:", error);
      }
    },
    async getDataDetail() {
      try {
        const response = await this.$crm_http.get(`/v1/transaction/event/${this.ids}/summary?filter=month`)
        this.chart = response.data.data.summary;
        this.details = response.data.data.event;
        this.series = response.data.data.summary.total_by_status;
        this.series2 = response.data.data.summary.total_by_range;
        this.chartOptions.labels = response.data.data.summary.status_transaction;
        this.chartOptions2.labels = response.data.data.summary.range;
      } catch (error) {
        console.error("Error fetching transaction events:", error);
      }
    },
    onCloseModal() {
      this.isModalSide = false;
      this.form = {
        career_id: "",
        full_name: "",
        email: "",
        phone_number: "",
        linkedin_url: "",
        resume_url: "",
        portfolio_url: "",
        location: "",
        current_company: "",
        current_level: "",
        website: "",
        notes: "",
      };
    },
    onAddApp() {
      this.isModalSide = true;
      this.isFormEdit = false;
    },
    async importExcelData() {
      try {
        const response = await this.$crm_http.get(`/v1/transaction/event/${this.ids}?page=1&per_page=10`)
        this.careerApplicationExcel = response.data.data.transaction_event;
      } catch (error) {
        console.error("Error fetching transaction events:", error);
      }
    },
    deleteItem(item) {
      this.isModal = true;
      this.id = item.id;
    },
    async watchItem(item) {
      try {
        const response = await this.$crm_http.get(`/v1/transaction/${item.id}`)
        this.applicantDetail = response.data.data;
      } catch (error) {
        console.error("Error fetching transaction events:", error);
      }

      this.isModalDetail = true;
    },
    async updateItem(item) {
      this.isFormEdit = true;
      this.id = item.id;
      try {
        const response = await this.$crm_http.get(`/v1/transaction/${item.id}`)
        const dateParts = response.data.data.birthday.split("/");
        const [day, month, year] = dateParts;
        this.dates = `${year}-${month}-${day}`;
        this.applicantDetail = response.data.data;
      } catch (error) {
        console.error("Error fetching transaction events:", error);
      }
      this.isModalSide = true;
    },
    async onSubmitApp() {
      try {
      
        await this.$crm_http.put(`/v1/transaction/${this.id}`, {
              gender: this.applicantDetail.gender,
              full_name: this.applicantDetail.user_name,
              phone: this.applicantDetail.phone,
              email: this.applicantDetail.email,
              birthday: this.applicantDetail.birthday,
              voucher_code: this.applicantDetail.voucher,
              motivation_note: this.applicantDetail.motivation_note,
            })
          } catch (err) {
            console.log(err);
            
          }
      this.onCloseModal();
      this.getCareerApplication();
    },
    async onDelete() {
      this.isLoading = true;
      try {
        await this.$crm_http.delete(`/v1/transaction/${this.id}`)
        this.isLoading = false;
        this.isModal = false;
      } catch (error) {
        console.error("Error fetching transaction events:", error);
        this.isLoading = false;
        this.isModal = false;
      }
    },
  },
  beforeCreate() {
    getAuthentification();
  },
  mounted() {
    this.importExcelData();
    this.getDataDetail();
    this.getCareerApplication();
  },
};
</script>

<style scoped>
.long-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 160px;
}
.tbody {
  margin: 0;
  font-weight: 400;
  border-collapse: collapse;
  font-size: 14px;
}
.tbody-color-red {
  color: #b80f0a;
}
.tbody-color-gray {
  color: #7b7e8c;
}
.container-tabel {
  background-color: white;
  margin: 20px;
  overflow-x: auto;
}
.tabel {
  position: relative;
  border: none;
  width: 100%;
  border-collapse: collapse;
}
.table-head {
  min-width: 200px;
  background-color: white;
  white-space: nowrap;
  width: auto;
  padding: 10px;
}
.border-tabel {
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 2px solid #e5e7e9;
}
.table-body {
  min-width: 200px;
  height: 141px;
  white-space: nowrap;
  width: auto;
  padding: 10px;
  text-align: center;
}
.table-floting {
  white-space: nowrap;
  width: auto;
  min-width: 200px;
  text-align: center;
  position: sticky;
  right: 0;
  background-color: white;
}
.warp-btn-action {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.btn-action {
  background-color: white;
  width: 79px;
  height: 38px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #575966;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #acafbf;
}
.btn-action-2 {
  background-color: white;
  color: #2d46cf;
  width: 79px;
  height: 38px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #2d46cf;
}
.container-tabel {
  background-color: white;
  margin: 20px;
  overflow-x: auto;
}
.list-date {
  width: 20px;
  height: 10px;
  border-radius: 100px;
  margin-right: 10px;
}
.line-chart {
  width: 100%;
  border: 1px solid #d3d6e1;
  border-radius: 8px;
  padding: 20px;
}
.pie-chart {
  width: 50%;
  border: 1px solid #d3d6e1;
  border-radius: 8px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.download-excel {
  cursor: pointer;
  border: 1px solid #d3d6e1;
  border-radius: 8px;
  padding: 8px;
}
.border-headdetail {
  width: 320px;
  border: 1px solid #d3d6e1;
  border-radius: 8px;
  padding: 16px 24px;
}
.headdetail {
  margin-top: 40px;
  background-color: white;
  padding: 24px;
  flex-direction: column;
}
.pagination-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination-page {
  border: 1px solid #d3d6e1;
  border-radius: 8px;
  padding: 10px;
  width: 50px;
  display: flex;
  justify-content: center;
  margin: 0px 10px;
}
.pagination-container {
  margin-top: 35px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination-container-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}
.action-head {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  font-weight: bold;
  font-size: 19px;
}

.f-search {
  border: 1px solid #d3d6e1;
  width: 350px;
  height: 45px;
  gap: 10px;
  display: flex;
  border-radius: 14px;
}

.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-left: 10px;
}

.f-input {
  width: 86%;
  background: transparent;
  border: none;
}

.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}

.header-info {
  margin: auto;
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
}

.header-left {
  display: flex;
}

.career-detail {
  margin: auto;
  padding: 80px 0;
  max-width: 900px;
  width: 100%;
}

.career-detail-item {
  margin-top: 30px;
}

.career-detail-item h1 {
  font-size: 26px;
}

.career-top {
  padding-top: 10px;
  display: flex;
  font-size: 16px;
  gap: 8px;
  align-items: center;
}

.career-info {
  color: #36373f;
}

.career-info-ctn {
  display: flex;
  gap: 2px;
  flex-direction: column;
  margin: 20px 0;
}

.career-title {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #36373f !important;
}

.information {
  padding-top: 20px;
}

.rounded-button {
  background-color: #f5f6f9;
  color: #36373f;
  border: 1px solid #162ca2;
  border-radius: 20px;
  /* Adjust the radius as needed */
  padding: 6px 20px;
  margin-right: 10px;
  /* Adjust spacing between buttons */
  display: flex;
  align-items: center;
}

.yellow-text {
  font-weight: 600;
  cursor: pointer;
  color: #162ca2;
  /* Apply yellow color to "Karir" text */
}

.icon {
  margin-bottom: -6px;
}

.header-right {
  display: flex;
  width: 30%;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
}

.header-right img {
  width: 100%;
  height: 100%;
}

.alert-info {
  position: fixed;
  width: 150px;
  padding: 10px;
  display: flex;
  justify-content: center;
  z-index: 99;
  top: 85px;
  right: 45px;
}

.ic-copy {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-weight: 400 !important;
  font-size: 17px;
  border: 1px solid #7b7e8c;
  padding: 15px;
}

.career-data .label {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 10px;
  margin: 15px 0;
}

.career-data .label div {
  background-color: #d3d6e1;
  padding: 5px 10px;
  border-radius: 30px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.career-data .label div p {
  font-size: 14px !important;
  font-weight: 400;
  padding: 0;
  margin: 0;
  margin-top: 2px;
  color: #36373f;
}
.btn-stoke {
  border-radius: 12px;
  color: #7b7e8c;
  font-weight: 400;
  gap: 10px;
  display: flex;
  padding: 7px 0px;
  margin: 5px;
}

.btn-stoke-blue {
  border: 1px solid #acafbf;
  border-radius: 12px;
  color: #162ca2;
  font-weight: 600;
  cursor: pointer;
  gap: 10px;
  margin-top: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 13px;
  background-color: white;
  margin: 5px;
}

.btn-stoke-blue span {
  font-family: "";
  font-weight: normal !important;
  font-size: 13px;
  min-width: max-content;
}

.link-cls {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.link-cls:hover {
  color: darkblue;
}

.info-icon-wrapper {
  position: relative;
  display: inline-block;
}

.line-under {
  border-bottom: 1px solid #acafbf;
}

.title-container {
  padding: 20px 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  font-size: 12px;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
}

.info-icon-wrapper:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.modal-body {
  text-align: left;
}

.data-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  padding: 20px 0;
}

.detail-data {
  width: 100%;
  text-align: left;
  border-radius: 10px;
  height: min-content;
}

.detail-label {
  color: #c3c3c6;
  font-size: small;
  margin: 0 !important;
}

.detail-value {
  margin: 0 !important;
  font-size: 15px;
}

.status-text {
  font-weight: bold !important;
  color: #162ca2 !important;
}

.action-head {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.my-datepicker {
  width: 100%;
}

.my-datepicker ::v-deep .mx-input {
  height: 54px !important;
  border-radius: 12px !important;
}

.f-search {
  border: 1px solid #d3d6e1;
  width: 100%;
  height: 55px;
  gap: 10px;
  display: flex;
  border-radius: 10px;
  background-color: white;
  padding-left: 20px;
  margin-top: -7px;
}

.f-search-ic {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 25px;
}

.f-input {
  width: 100%;
  background: transparent;
  border: none;
  padding-top: 5px;
}

.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.career-detail-modal {
}

.career-detail-item-modal {
  margin-top: 30px;
}

.career-detail-item-modal h1 {
  font-size: 31px;
}

.form-ctn {
  margin-top: 30px;
}

.rounded-sm {
  border-radius: 11px !important;
  background-color: white;
}

.form-ctn-left {
}

.form-ctn-right {
}

.form-label {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

::v-deep .v-input input {
  padding: 12px 30px;
  font-family: "Poppins", sans-serif;
}

.form-label {
  border-radius: 10px;
}

::v-deep v-text-field {
  border-radius: 10px;
}

::v-deep .row {
  margin: -12px;
}

::v-deep .col {
  padding: 5px 20px !important;
}

::v-deep .text-form .v-input__slot {
  border-radius: 10px;
  border: 1px solid rgba(211, 214, 225, 1);
  background-color: white;
}

::v-deep .form-upload {
  height: 62px !important;
  padding-left: 10px;
  border: 1px solid rgba(211, 214, 225, 1);
  border-radius: 10px;
}

::v-deep .form-upload .v-input__slot {
  height: 62px !important;
  background-color: transparent;
  border: none !important;
}

::v-deep .form-upload fieldset {
  display: none !important;
  /* Menyembunyikan fieldset */
}

::v-deep .form-upload .v-text-field__details {
  display: none;
  border: none !important;
}

.note-file {
  font-weight: 400;
  font-size: 12px;
  color: #b80f0a;
  margin-bottom: 0px !important;
}

.career-detail-modal h3 {
  text-align: right;
}
</style>
