<template>
  <div class="ma-10">
    <div class="ctn-head">
      <h2 style="font-weight: 700; font-size: 28px; color: #36373f">
        {{ category ? "RIWAYAT" : "" }} {{ this.category_name.toUpperCase() }}
      </h2>
      <button
        v-if="!category"
        class="btn-stoke-blue"
        @click="
          $router.push(
            `/crm-event/history/${category_name
              .toLowerCase()
              .replace(/\s+/g, '-')}/${id}`
          )
        "
      >
        <IconReverse />
        <span>Riwayat</span>
      </button>
    </div>
    <div class="container-box mt-10" style="padding: 35px 25px">
      <v-row gap="5">
        <v-col cols="3">
          <div class="action-head">
            <div class="f-search">
              <div class="f-search-ic">
                <img src="/img/icons/search.png" alt="Search Ic" />
              </div>
              <div style="width: 100%">
                <v-text-field
                  v-model="filter.position"
                  type="text"
                  class="f-input"
                  placeholder="Cari Pekerjaan"
                  dense
                  flat
                  solo
                ></v-text-field>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="2">
          <date-picker
            class="my-datepicker"
            v-model="filter.date"
            placeholder="Date time"
            valueType="format"
            range
          ></date-picker>
        </v-col>
        <v-col cols="2">
          <v-select
            outlined
            color="#ACAFBF"
            placeholder="Tipe Pekerjaan"
            v-model="filter.work_type"
            :items="workTypeList"
            :item-text="'name'"
            :item-value="'value'"
            style="border-radius: 10px !important; background-color: white"
            hide-details
          />
        </v-col>
        <v-col cols="2">
          <Button
            name="TERAPKAN"
            width="100%"
            height="54px"
            @click="() => getCareers()"
          />
        </v-col>
      </v-row>
      <div class="list-career">
        <div
          class="list-career-ctn"
          v-for="(data, idx) in careers"
          :key="idx"
          @click="
            category
              ? $router.push(
                  `/crm-event/history/${category_name
                    .toLowerCase()
                    .replace(/\s+/g, '-')}/${data.id}/history`
                )
              : $router.push(
                  `/crm-event/${category_name
                    .toLowerCase()
                    .replace(/\s+/g, '-')}/${data.id}`
                )
          "
        >
          <h3 class="mb-3">{{ data.name }}</h3>
          <p class="text-limit">
            {{ data.schedule }}
          </p>
          <div style="height: 44px">
            <p
              class="text-limit"
              style="font-weight: 400; -webkit-line-clamp: 2"
            >
              {{ data.learning_method === "Online" ? "Via Zoom" : "On Site" }}
            </p>
          </div>
          <div class="box-total" style="padding: 20px">
            <p>
              <b>{{ data.total_registered }}</b>
            </p>
            <p style="font-size: 12px">Pendaftar</p>
          </div>
          <p class="mt-4">
            Batas Pendaftaran
            <b style="font-family: 'Poppins'">{{
              moment(data.registration_close_date)
            }}</b>
          </p>
        </div>
      </div>
      <div class="pagination-container">
        <div class="pagination-container-btn">
          <Button
            v-if="
              Math.floor(
                this.careers.length / 10 +
                  (this.careers.length % 10 === 0 ? 0 : 1)
              ) > 1
            "
            class="m-auto"
            name="SELANJUTNYA"
            height="54px"
          />
        </div>
        <div class="pagination-page-container" style="width: 20%">
          <h5>Halaman</h5>
          <h5 class="pagination-page">{{ currentPage }}</h5>
          <h5>
            dari
              {{
                Math.floor(
                  this.careers.length / 10 +
                    (this.careers.length % 10 === 0 ? 0 : 1)
                )
              }}
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconReverse from "@/components/icons/Reverse.vue";
import Button from "@/components/Button.vue";
import DatePicker from "vue2-datepicker";

import moment from "moment";
import "vue2-datepicker/index.css";

export default {
  props: ["id", "category"],
  name: "CareerEventCard",
  components: {
    IconReverse,
    DatePicker,
    Button,
  },
  data() {
    return {
      filter: {
        position: "",
        work_type: "",
        start_date: "",
        end_date: "",
        date: [],
      },
      category_name: "",
      careers: [],
      currentPage: 1,
      total: 0,
      workTypeList: [
        {
          name: "Online",
          value: "Online",
        },
        {
          name: "Offlie",
          value: "Offlie",
        },
      ],
    };
  },
  methods: {
    async getCareers() {
      this.filter.start_date = this.filter.date[0] || "";
      this.filter.end_date = this.filter.date[1] || "";

      try {
        const response = await this.$crm_http.get(`/v1/transaction/event/category/${
            this.id
          }?page=1&per_page=12&history=${
            this.category ? true : false
          }&keyword=${this.filter.position}&learning_method=${
            this.filter.work_type
          }&date_period=${
            this.filter.start_date === "" && this.filter.end_date === ""
              ? ""
              : this.filter.start_date + ":" + this.filter.end_date
          }`)
        this.careers = response.data.data.events === null ? [] : response.data.data.events;
        this.category_name = response.data.data.category_name;
      } catch (error) {
        console.error("Error fetching transaction events:", error);
      }
    },
    moment(date) {
      return moment(date).format("DD MMMM YYYY");
    },
  },
  mounted() {
    this.getCareers();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getCareers();
    });
  },
};
</script>

<style scoped>
.pagination-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination-page {
  border: 1px solid #d3d6e1;
  border-radius: 8px;
  padding: 10px;
  width: 50px;
  display: flex;
  justify-content: center;
  margin: 0px 10px;
}
.pagination-container {
  margin-top: 35px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination-container-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}
.ctn-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action-head {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.my-datepicker {
  width: 100%;
}

.my-datepicker ::v-deep .mx-input {
  height: 54px !important;
  border-radius: 12px !important;
}

.f-search {
  border: 1px solid #d3d6e1;
  width: 100%;
  height: 55px;
  gap: 10px;
  display: flex;
  border-radius: 10px;
  background-color: white;
  padding-left: 20px;
  margin-top: -7px;
}

.f-search-ic {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 25px;
}

.f-input {
  width: 100%;
  background: transparent;
  border: none;
  padding-top: 5px;
}

.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}

.list-career {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px 20px;
}

.list-career-ctn {
  padding: 15px 20px;
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid #d3d6e1;
}

.list-career-ctn img {
  width: 130px;
}

.list-career-ctn h2 {
  margin: 0;
}

.list-career-ctn p {
  margin: 0;
  color: #36373f;
  font-size: 14px;
}

.career-data .label {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 15px;
}

.career-data .label div {
  padding: 5px 10px;
  border-radius: 30px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.career-data .label div p {
  font-size: 11px !important;
  font-weight: 400;
  padding: 0;
  margin: 0;
  margin-top: 2px;
  color: #36373f;
}

.box-total {
  padding: 5px 10px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin: 10px 0;
  width: min-content;
  background-color: #f1f6ff;
}

.btn-stoke-blue {
  border: 1px solid #acafbf;
  border-radius: 12px;
  color: #acafbf;
  font-weight: 600;
  cursor: pointer;
  gap: 10px;
  margin-top: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  padding: 11px 0;
  background-color: white;
  margin: 20px 0 0 20px !important;
}

.text-limit {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  visibility: visible;
  font-weight: 500;
}

.btn-stoke-blue span {
  font-family: "Poppins", sans-serif;
  font-weight: normal !important;
  font-size: 16px;
  min-width: max-content;
}
</style>
