<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

p,
h1,
h2,
h3,
h4,
h5,
span,
button,
div {
  font-family: "Poppins", sans-serif !important;
}

.container-custom {
  background: #f5f5f5;
  min-height: 100vh;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.container-box {
  background-color: white;
  padding: 20px;
  border-radius: 20px;
}
.title-page {
  display: flex;
  justify-content: space-between;
}

.v-slide-group__wrapper {
  background: #f5f5f5;
}
.table {
  border-radius: 13px !important;
  padding: 20px;
  box-shadow: none !important;
  border: none !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.spacing-switch {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 15px 0;
}

.theme--light.v-pagination .v-pagination__item--active {
  background-color: transparent !important;
}

.v-pagination .primary {
  color: #4456b4 !important;
}

.v-pagination .v-pagination__item {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0),
    0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
  background-color: transparent !important;
  color: #acafbf;
  font-weight: 700;
}

.v-pagination .v-pagination__navigation {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0),
    0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
  background-color: transparent !important;
}

.v-data-table__empty-wrapper {
  display: none !important;
}
</style>
