<template>
  <div class="ma-10">
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <Modal v-if="toggleValue" @close="() => (toggleValue = false)" width="40%">
      <h1 class="modal-title">Konfirmasi Reactive Data</h1>
      <p>Apakah kamu yakin ingin mengaktifkan ulang data ini?</p>
      <div style="display:flex;">
        <v-btn rounded="lg" size="x-large" @click="cancelActive()">Batal</v-btn>
        <v-btn rounded="lg" size="x-large" @click="activeUser()">Aktifkan</v-btn>
      </div>
    </Modal>
    <!-- form on active -->
    <div class="container-box" style="margin-bottom: 20px">
      <v-card-text>
        <Loading v-if="isLoading" />
        <v-card-title style="display: flex; align-items: center">
          <h1 style="margin-right: auto; margin-bottom: 0; font-size: 30px">
            Edit Data User
          </h1>
          <p
            style="
              margin-left: auto;
              margin-right: 20px;
              margin-bottom: 0;
              font-size: 14px;
            "
          >
            Geser untuk mengaktifkan user ini
          </p>
          <v-switch
            v-model="toggleValue"
            color="blue"
            dark
            @change="toggleColor()"
          ></v-switch>
        </v-card-title>
        <!-- <p>{{ toggleValue }}</p> -->

        <div class="form-ctn" style="margin-bottom: 20px">
          <div class="content-form" style="margin-bottom: 20px">
            <p>Title</p>
            <v-radio-group v-model="formData.gender" row>
              <v-radio
                label="Tuan"
                value="Tuan"
                class="form-radio"
                color="blue"
              ></v-radio>
              <v-radio
                label="Nyonya"
                value="Nyonya"
                class="form-radio"
                color="blue"
              ></v-radio>
              <v-radio
                label="Nona"
                value="Nona"
                class="form-radio"
                color="blue"
              ></v-radio>
            </v-radio-group>
          </div>
          <div class="content-form">
            <p>Nama</p>
            <v-text-field
              outlined
              v-model="formData.full_name"
              class="form-input"
              dense
              clearable
            ></v-text-field>
          </div>
          <div class="content-form">
            <p>No. Handphone</p>
            <v-row>
              <v-col cols="2" class="phone">
                <div
                  class="phone-logo"
                  style="margin-left: 0px; margin-bottom: 50px"
                >
                  <img
                    src="public/img/indonesia.png"
                    :width="27"
                    :height="18"
                    alt="Indonesia Flag"
                  />
                  <p>+62</p>
                </div>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  v-model="formData.phone_number"
                  class="form-input"
                  dense
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <div class="content-form">
            <p>Email</p>
            <v-text-field
              outlined
              v-model="formData.email"
              class="form-input"
              dense
              clearable
            ></v-text-field>
          </div>
          <div class="content-form">
            <p>Tgl.Lahir</p>
            <v-text-field
            outlined
            v-model="formData.birthdate"
            class="form-input"
            type="date"
            clearable
            dense
          ></v-text-field>
          </div>
          <div class="content-form">
            <p>Roles</p>
            <v-select
              label="filter"
              v-model="formData.role"
              :items="categories"
              :item-text="'name'"
              :item-value="'value'"
              hide-details="auto"
              class="select-chart"
              solo
              style="width: 200px"
            ></v-select>
          </div>
          <div class="content-form">
            <p>Alasan</p>
            <v-text-field
              outlined
              v-model="formData.roles"
              class="form-input"
              dense
              clearable
            ></v-text-field>
          </div>
          <div
            class="submit-box"
            style="display: flex; justify-content: flex-end"
          >
            <v-btn color="grey" @click="onCancel" style="margin-right: 10px">
              Batalkan
            </v-btn>
            <v-btn color="primary" @click="onSubmit" :disabled="!validData">
              Simpan Data
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/ilustration/Loading.vue";
import Modal from "@/components/Modal.vue";
import moment from 'moment';
import Popup from "@/components/others/Popup.vue";

export default {
  name: "FormUserNonActive",
  data() {
    return {
      name: "Edit Data User",
      role: 2,
      id: null,
      isLoading: false,
      formData: {},
      users: [],
      // validData: true,
      toggleValue: false,
      isModal: false,
      menu: false,
      message: "",
      isPopup: false,
      watchIndex: 0,
      filter: {
        category: "MONTH",
      },
      categories: [
        {
          name: "Admin",
          value: "ADMIN",
          user_type: 1,
        },
        {
          name: "User",
          value: "USER",
          user_type: 2,
        },
      ],
    };
  },
  components: {
    Loading,
    Modal,
    Popup
  },
  computed: {
    validData() {
      return this.formData.id && this.formData.gender && this.formData.full_name &&  this.formData.phone_number && this.formData.email && this.formData.birthdate && this.formData.role;
    },
  },
  methods: {
    async getUserNonActive() {
      await this.$web_http
        .get(`/v1/user/${this.$route.params.id}`)
        .then((response) => {
          const data = response.data.data.user
            this.formData = {
              ...data,
              birthdate: moment(data.birthdate).format("YYYY-MM-DD"),
              role: data.user_type.id
            }
        })
        .catch((err) => {
          this.message = err.response.data.data
        this.isPopup = true
        });
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        const birthdateWithTime = this.formData.birthdate ? `${this.formData.birthdate}T00:00:00Z` : null;
        this.formData.role = this.formData.role === 'ADMIN' ? 1 : (this.formData.role === 'USER' ? 2 : this.formData.role);
        await this.$crm_http.put("/v1/user", {
            ...this.formData,
            id: this.formData.id,
            full_name: this.formData.full_name,
            phone_number: this.formData.phone_number,
            email: this.formData.email,
            birthdate: birthdateWithTime,
            gender: this.formData.gender,
            role: this.formData.role,
        });
        setTimeout(() => {
          this.$router.push("/user-tidak-aktif");
        }, 2000);
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    onCancel() {
      // Add cancel logic here
      this.$router.push('/user-tidak-aktif');
    },
    toggleColor() {
      if (this.toggleValue) {
        this.isModal = true;
      }
    },
    cancelActive(){
      this.toggleValue = false;
    },
    async activeUser(){
      this.isLoading = true;
      try {
        await this.$crm_http.put("/v1/user/active/" + this.formData.id);
        setTimeout(() => {
          this.$router.push("/user-tidak-aktif");
        }, 2000);
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getUserNonActive();
  },
  // toggleColor() {
  //   // Ketika toggle diaktifkan, tampilkan modal
  //   if (this.toggleValue) {
  //     this.modalVisible = true;
  //   }
  // },
  // cancelActivation() {
  //   this.toggleValue = false;
  //   this.modalVisible = false;
  // },
  // confirmActivation() {
  //   this.modalVisible = false;
  // },
};
</script>

<style scoped>
.satu {
  font-size: small; /* Ukuran font kecil */
  color: blue; /* Warna tulisan biru */
}

.container-box {
  padding: 45px 50px; /* Atas dan bawah 20px, Kanan dan kiri 30px */
}

.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaf9;
  padding: 50px 0;
  min-height: 100vh;
  width: 100vw;
}
.submit-box {
  display: flex;
  justify-content: flex-end; /* Untuk membuat tombol-tombol berada di sebelah kanan */
}

.submit-box v-btn {
  margin-right: 10px; /* Jarak margin kanan antar tombol */
}

.submit-box v-btn:nth-child(1) {
  background-color: blue; /* Warna biru untuk tombol pertama */
}

.submit-box v-btn:nth-child(2) {
  background-color: grey; /* Warna abu-abu untuk tombol kedua */
}

.cart-form {
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

h1 {
  text-align: center;
  margin-bottom: 40px;
  padding: 20px;
  font-size: 24px;
  color: #333;
}

.form-ctn {
  display: flex;
  flex-direction: column;
}

.content-form {
  margin-bottom: 20px;
}

.content-form p {
  margin-bottom: 8px;
  font-weight: bold;
  color: #555;
}

.form-input {
  width: 100%;
}

.form-radio {
  margin-right: 20px;
}

.submit-box {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[color="blue"] {
  background-color: #007bff;
  color: white;
}

button[color="grey"] {
  background-color: #6c757d;
  color: white;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
.content-form {
  margin-bottom: 16px;
}

.phone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.phone-logo {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.phone-logo p {
  margin-left: 8px;
  margin: 0;
}

.form-input {
  width: 100%;
}
</style>
