<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.94021 14.51C7.32021 14.28 6.77021 13.83 6.42021 13.19C5.62021 11.73 6.11021 9.83001 7.53021 8.95001L9.87021 7.5C11.2802 6.62 13.1002 7.09999 13.9002 8.54999C14.7002 10.01 14.2102 11.91 12.7902 12.79L12.4802 13.01"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.1102 9.45001C16.7302 9.68001 17.2802 10.13 17.6302 10.77C18.4302 12.23 17.9402 14.13 16.5202 15.01L14.1802 16.46C12.7702 17.34 10.9502 16.86 10.1502 15.41C9.35019 13.95 9.84019 12.05 11.2602 11.17L11.5702 10.95"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "LinkCircle",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
